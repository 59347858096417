/* globals zoomSdk */

interface ExternalLinkProps {
  href: string;
  className?: string;
  children: React.ReactNode;
  callback?: () => void;
}
const ExternalLink = ({ href, className = '', children, callback }: ExternalLinkProps) => {
  const handleClick = async (e: any) => {
    e.preventDefault();
    zoomSdk.openUrl({ url: href }).then(() => {
      if (callback) {
        callback();
      }
    });
  };

  return <a href={href} onClick={handleClick} className={`cursor-pointer ${className}`}>{children}</a>;
};

export default ExternalLink;
