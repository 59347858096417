import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { getAuth } from '@services/identity.service';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Sidenav, Header, ZoomHeader, MicrosoftTeamsHeader } from '@molecules/index';
import { logsToAwsCloudWatch } from '@services/logger.service';
import { useTheme } from 'next-themes';

import { isZoomApp } from '@zoom-app/helpers';
import { isMicrosoftTeamsApp } from '@teams-app/helpers';

library.add(fas);

const PresenterLayout = ({ children }) => {
  const router = useRouter();
  const [auth, setAuth] = useState();
  const { setTheme } = useTheme();

  function logError(msg, url, lineNo, columnNo, error) {
    const errorMessage = msg.toLowerCase();
    let errorType = 'BrowserError(window.onError): ';

    if (errorMessage.includes('script error')) {
      errorType = 'Script Error: ';
    }

    const body = {
      logMessage: errorType + msg,
      logData: {
        userId: auth?.id || '',
        url,
        error: { message: error.message, stack: error.stack },
        lineNo,
        columnNo,
        location: {
          host: window.location.host,
          hostname: window.location.hostname,
          href: window.location.href,
          origin: window.location.origin,
          pathname: window.location.pathname
        }
      }
    };
    logsToAwsCloudWatch(body);
  }
  const getLayout = () => {
    if (isZoomApp()) {
      if (router.pathname === '/zoom-app/login') {
        return (
          <div className="flex flex-col h-screen">
            <div className="h-full overflow-hidden bg-grey-50">
              {children}
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex flex-col h-screen">
            <ZoomHeader {...children.type.Header} />
            <div className="flex-1 bg-grey-50 overflow-y-auto">
              {children}
            </div>
          </div>
        );
      }
    }
    if (isMicrosoftTeamsApp()) {
      return (
        <div className="flex flex-col h-screen">
          <MicrosoftTeamsHeader {...children.type.Header} />
          <div className="h-[calc(100vh-104px)] md:h-[calc(100vh-57px)] overflow-hidden bg-grey-50">
            {children}
          </div>
        </div>
      );
    }
    return (
      <div className="h-screen flex">
        <Sidenav isLoggedIn={auth?.id !== undefined} {...children.type.Header} />
        <div className="flex flex-1 grow flex-col h-full overflow-hidden bg-grey-50 dark:bg-grey-900">
          {children}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTheme('light');
    const tmpAuth = getAuth();
    if (tmpAuth) {
      setAuth(tmpAuth);
    }
  }, []);

  useEffect(() => {
    window.onerror = logError;
  }, []);

  useEffect(() => {
    const authObj = getAuth();
    if (process.env.NEXT_PUBLIC_APP_ENV === 'qa' || process.env.NEXT_PUBLIC_APP_ENV === 'uat') {
      authObj && !authObj.is_internal && router.push('/accessibility');
    }
  }, []);

  return getLayout();
};

PresenterLayout.propTypes = {
  children: PropTypes.node
};

export default PresenterLayout;
