import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import Home from '../../public/images/sidebar/home_icon.svg';
import Pricing from '../../public/images/sidebar/payments_icon.svg';
import Settings from '../../public/images/sidebar/settings_icon.svg';
import Logout from '../../public/images/sidebar/logout_icon.svg';
import Presentations from '../../public/images/sidebar/home_storage.svg';
import MagicWand from '../../public/images/sidebar/magic_wand.svg';
import LogoCircle from '../../public/images/logos/logo_circle.svg';
import LogoText from '../../public/images/logos/logo_text.svg';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';

import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { TeamsDataContext } from '@components/context/TeamsContext';
import Avatar from '@atoms/Avatar';
import Tooltip from '@components/Presentation/atoms/Tooltip';
import { Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from '@atoms/new/dropdown';
import Workspace from './Workspace';
import { useDisclosure } from '@nextui-org/react';
import Button from '@atoms/new/button';

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { getUserEmailByUcc, updateUserUcc } from '@services/youtube-platform.service';
import { getAuth } from '@services/identity.service';
import { usePopupContext } from '@components/context/screenShotPupUpContext';
import ScreenShotsPopup from '@components/Screenshot-Popup';
import ThemeToggle from '@components/Layout/themeToggle';

type LinkSections = {
  name: string;
  url?: string;
  icon: React.ReactNode;
  isExternal?: boolean;
  iconRight?: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

export default function Sidenav() {
  const { pathname, push, query } = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const isReportsPage = pathname.startsWith('/stats') && query?.details === 'true';
  const { isPopupVisible } = usePopupContext();

  const { doLogout, ownTeam, currentTeam, teams, selectTeam } = useContext(TeamsDataContext);

  const isExpanded = !isReportsPage;
  const teamName = currentTeam?.billing_account?.name;

  const domains = ['@streamalive', '@cello.so'];
  const [isInternalUser, setIsInternalUser] = useState(false);
  const isEmailIncluded = (email: string) => {
    const emailDomain = email?.substring(email?.indexOf('@'));
    const check = domains?.some((domain) => emailDomain?.startsWith(domain));
    setIsInternalUser(check);
  };

  const linkSections: LinkSections[][] = [
    [
      {
        name: 'Home',
        url: '/',
        icon: <Home fontSize='small' />
      },
      {
        name: 'Sessions',
        url: '/sessions',
        icon: <CalendarMonthOutlinedIcon fontSize='small' />
      }
    ],
    [
      {
        name: 'My presentations',
        url: '/presentations',
        icon: <Presentations />
      },
      {
        name: 'Templates',
        url: '/templates',
        icon: <MagicWand />
      },
      {
        name: 'Quick interaction links',
        url: '/interaction-links',
        icon: <LinkRoundedIcon fontSize='small' />
      },
      {
        name: 'Themes',
        url: '/themes',
        icon: <FormatPaintOutlinedIcon fontSize='small' />,
      },
    ],
    [
      {
        name: 'Reports',
        url: '/stats',
        icon: <InsertChartOutlinedRoundedIcon fontSize='small' />
      }
    ],
    [
      {
        name: 'Help',
        url: 'https://help.streamalive.com/en/',
        isExternal: true,
        icon: <SupportOutlinedIcon fontSize='small' />,
        iconRight: <LaunchRoundedIcon fontSize='small' className='text-grey-700' />
      },
      {
        name: 'Integrations',
        url: '/integrations',
        icon: <ExtensionOutlinedIcon fontSize='small' />,
      },
      {
        name: 'Cello',
        icon: '',
        className: 'classCello'
      },
      {
        name: 'Pricing',
        url: '/pricing',
        icon: <Pricing fontSize='small' />
      },
      {
        name: 'Settings',
        url: '/settings',
        icon: <Settings fontSize='small' />
      }
    ],
    [
      {
        name: 'Logout',
        icon: <Logout fontSize='small' />,
        onClick() {
          doLogout();
          push('/');
        },
      }
    ],

  ];

  const showBanner = () => (
    ownTeam?.billing_account?.purchase_plan?.name === 'Free Plan'
  );

  const getActiveLinkClass = (link: string, defaultClass: string, activeClass: string) => {
    if (!link) return defaultClass;
    if (pathname === '/' && link === '/') return activeClass;
    return (pathname !== '/' && link !== '/' && pathname.startsWith(link)) ? activeClass : defaultClass;
  };

  const width = isExpanded ? 'w-[300px] max-w-[300px] min-w-[300px]' : '';

  useEffect(() => {
    isEmailIncluded(currentTeam?.email);
  }, [currentTeam]);

  const auth = getAuth();
  const getActiveUcc = async() => {
    const { ucc } = await (window as any).Cello('getActiveUcc');

    const res = await getUserEmailByUcc(ucc);

    if (!res?.entity?.email) {
      await updateUserUcc(auth.id, ucc);
    }
  };

  return (
    <>
      <header className={`${width} bg-white dark:bg-black/90 pt-6 pb-4 flex flex-col h-screen overflow-auto relative`}>
        <ThemeToggle isExpanded={isExpanded} />

        <div className='mb-6'>
          {isExpanded && <LogoText className="ml-9 text-grey-900 dark:text-white" />}
          {!isExpanded && <LogoCircle className="mx-auto" />}

        </div>

        <div className={`${isExpanded ? 'px-6' : ''} border-t border-b border-grey-100 dark:border-grey-900 `}>
          <Dropdown
            placement='bottom-start'
            isOpen={isDropdownOpen}
            className='w-[300px]'
            onClose={() => {
              setIsDropdownOpen(false);
            }}>
            <DropdownTrigger>
              <button
                className='aria-expanded:scale-[1] w-full focus-visible:outline-none'
                onClick={() => {
                  setIsDropdownOpen(prev => !prev);
                }}>
                <div className={`${isExpanded ? 'px-2' : 'px-6'} py-2 flex items-center`}>
                  <div className='flex-1'>
                    <p className='font-[500] text-sm flex gap-2 items-center'>
                      <Avatar
                        w='8'
                        h='8'
                        isProfile
                        name={teamName} />
                      {isExpanded && teamName && teamName}
                    </p>
                  </div>
                  {isExpanded && <button>
                    <KeyboardArrowDownRoundedIcon fontSize='small' />
                  </button>}
                </div>
              </button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={[currentTeam?.id || '']}
            >
              <DropdownSection showDivider
                className="!m-0"
                classNames={{
                  divider: 'mt-1 mb-2',
                }}>
                <DropdownItem isDisabled>{currentTeam?.email}</DropdownItem>
              </DropdownSection>
              <DropdownSection showDivider title='WORKSPACE' id='workspace-section'
                className="!m-0"
                classNames={{
                  divider: 'my-1',
                }}>
                {teams.map((team: { id: string | number | undefined; billing_account: { name: string; }; }) => (
                  <DropdownItem
                    className='h-9.5'
                    endContent={currentTeam?.id === team.id ?
                      <Tooltip
                        content='Workspace settings'
                      >
                        <Button
                          kind='icon-only'
                          size='sm'
                          className='relative right-0 '
                        >
                          <Settings
                            onClick={() => {
                              onOpen();
                              setIsDropdownOpen(false);
                            }}
                          />
                        </Button>
                      </Tooltip> : null}
                    startContent={currentTeam?.id === team.id ? <DoneRoundedIcon fontSize='small' /> : <div className='w-5' />}
                    selectedIcon={() => null}
                    key={team.id}
                    onClick={() => {
                      if (team.id === currentTeam?.id) return;
                      selectTeam(team);
                      setIsDropdownOpen(false);
                      router.push('/');
                    }}
                  >
                    {team.billing_account.name}
                  </DropdownItem>

                ))}
              </DropdownSection>
              <DropdownItem key="delete" onClick={() => {
                doLogout();
                push('/');
              }}>
                <p className='text-red-500 flex gap-2'>
                  <Logout /> Log out
                </p>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className='mt-6 text-sm'>
          {linkSections.map((section, index) => (
            <div key={index} className={'flex flex-col gap-2 mb-2'}>
              {section.map((link, linkIndex) => (
                <React.Fragment key = {linkIndex}>
                  {link?.name === 'Cello' ? <div key={link.name}
                    content={link.name}
                  >
                    <div className={`celloClass relative h-8 m-auto cursor-pointer rounded-md hover:bg-grey-100 hover:dark:bg-grey-800 flex font-medium text-grey-700 dark:text-grey-200
                      ${isExpanded ? 'w-[84%] px-2 py-1.5 gap-4' : 'w-fit px-2'}`} onClick={getActiveUcc}>
                      <div className='h-full flex w-full items-center'>
                        <MonetizationOnOutlinedIcon fontSize='small' />
                        {isExpanded &&
                        <div className='flex items-center relative'>
                          <p className='ml-4'>Refer a friend</p>
                          <p className='absolute -right-[101%] bg-grey-200 dark:bg-grey-800 p-1 rounded-sm text-grey-900 dark:text-grey-200 text-xs'>Earn $100</p>
                        </div>
                        }
                      </div>
                      <div className='announcement'></div>
                    </div>

                  </div> :
                    <Tooltip key={link.name}
                      content={link.name}
                      placement='left'
                      isDisabled={isExpanded}
                    >
                      <Link
                        href={link?.url || ''}
                        onClick={link?.onClick || (() => { })}
                        className={` rounded-md flex items-center mx-6 px-2 py-1.5 h-8 gap-4 ${getActiveLinkClass(link?.url || '', 'text-grey-700 hover:bg-grey-100 dark:text-grey-200 hover:dark:bg-grey-800 font-medium', 'bg-red-50 text-red-500 dark:text-red-500 dark:bg-white/20 dark:border dark:border-grey-700/50 font-semibold')}`}
                        target={link?.isExternal ? '_blank' : undefined}
                      >
                        <div className={`${link?.className}  w-5 h-5 flex items-center ${getActiveLinkClass(link.url || '', 'text-grey-700 dark:text-grey-200', 'text-red-500 dark:text-red-500')}`}>
                          {link.icon}
                        </div>
                        {isExpanded && <span className='text-sm'>{link.name}</span>}
                        {isExpanded && link.iconRight && <div className='ml-auto flex'>{link.iconRight}</div>}
                      </Link>
                    </Tooltip>
                  }
                </React.Fragment>
              ))}
              {index !== linkSections.length - 1 && <div className='h-[1px] bg-grey-100 dark:bg-grey-900 my-2 mx-8' />}
            </div>
          ))}
        </div>

        {showBanner() && isExpanded && (<div className=' mx-6 border-t border-grey-100 dark:border-grey-900 py-6 mt-auto px-2'>
          <div className='flex items-center gap-2  font-[500]'>
            <div className='bg-red-500 text-white flex text-xs rounded-md p-[6px] leading-[8px]'>PRO</div>
            <div className='text-sm '>Upgrade to Pro</div>
          </div>
          <p className='text-xs text-grey-700 my-2'>Unlock more participants and remove StreamAlive branding</p>
          <Link href='/pricing' className='text-blue-600 text-sm font-semibold'>View plans</Link>
        </div>)
        }
        <Workspace isOpen={isOpen} onOpenChange={onOpenChange} />
      </header >

      {isPopupVisible &&
        <ScreenShotsPopup />
      }
    </>
  );
}
