import { getWithAuth, getWithOutAuth, postWithAuth } from '@services/http.service';
import { UrlParamsReplace } from '@services/url.service';

import type { zoomAuthEventType } from '@lib/zoom-helpers/types';

const ZOOM_USER_PLATFORM_API = (id: string) => UrlParamsReplace('users/:id/zoom-platforms', { id });
const ZOOM_EVENTS = (platformId: string) => UrlParamsReplace('platforms/zoom/events', {}, { platformId });
const ZOOM_EVENT_SESSIONS = (platformId: string, eventId: string) => UrlParamsReplace('platforms/zoom/events/:eventId/sessions', { eventId }, { platformId });
const ZOOM_EVENT_JOIN_TOKEN = (eventId: string, sessionId: string) => UrlParamsReplace('platforms/zoom/events/:eventId/sessions/:sessionId/join-token', { eventId, sessionId });
const ZOOM_GET_CHALLENGE_CODE = () => UrlParamsReplace('platforms/zoom/get-challenge-code');
const ZOOM_AUTH_CALLBACK = (eventPayload: zoomAuthEventType) => UrlParamsReplace('platforms/zoom/auth', {}, eventPayload);
const ZOOM_RESOURCE_API = (resourceType: string, resourceId: string, queryParams: object) => UrlParamsReplace(
  'platforms/zoom/:resourceType/:resourceId',
  { resourceType, resourceId },
  queryParams
);
const ZOOM_REGISTER_RESOURCE_API = (resourceType: string, resourceId: string) => UrlParamsReplace(
  'platforms/zoom/:resourceType/:resourceId/register',
  { resourceType, resourceId }
);
const ZOOM_GET_RECORDING_TOKEN_API = (resourceType: string, resourceId: string) => UrlParamsReplace(
  'platforms/zoom/:resourceType/:resourceId/local-recording-token',
  { resourceType, resourceId }
);
const ZOOM_GET_ZAK_TOKEN_API = (queryParams: object) => UrlParamsReplace(
  '/platforms/zoom/zak',
  {},
  queryParams
);

const ZOOM_RESOURCES_API = (resourceType: string, queryParams: object) => UrlParamsReplace(
  'platforms/zoom/:resourceType',
  { resourceType },
  queryParams
);

export const getZoomUserPlatform = (id: string) => getWithAuth(ZOOM_USER_PLATFORM_API(id));

export const getZoomEvents = (platformId: string) => getWithAuth(ZOOM_EVENTS(platformId), true);

export const getZoomEventSessions = (platformId: string, eventId: string) => getWithAuth(ZOOM_EVENT_SESSIONS(platformId, eventId), true);

export const getZoomEventJoinToken = (eventId: string, sessionId: string) => getWithAuth(ZOOM_EVENT_JOIN_TOKEN(eventId, sessionId), true);

export const getZoomChallengeCode = () => getWithAuth(ZOOM_GET_CHALLENGE_CODE());

export const zoomAuthCallback = (eventPayload: zoomAuthEventType) => getWithOutAuth(ZOOM_AUTH_CALLBACK({ ...eventPayload, state: decodeURIComponent(eventPayload.state) }));

export const getZoomResource = (resourceType: string, resourceId: string, queryParams: object, rejectFalseResponse = true) => getWithAuth(ZOOM_RESOURCE_API(resourceType, resourceId, queryParams), rejectFalseResponse);

export const registerForResource = (resourceType: string, resourceId: string, payload: object) => postWithAuth(ZOOM_REGISTER_RESOURCE_API(resourceType, resourceId), payload);

export const getZoomRecordingToken = (resourceType: string, resourceId: string, payload: object) => postWithAuth(ZOOM_GET_RECORDING_TOKEN_API(resourceType, resourceId), payload);

export const getZoomZakToken = (queryParams: object) => getWithAuth(ZOOM_GET_ZAK_TOKEN_API(queryParams));

export const getZoomResources = (resourceType: string, queryParams: object, rejectFalseResponse = true) => getWithAuth(ZOOM_RESOURCES_API(resourceType, queryParams), rejectFalseResponse);
