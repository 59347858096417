import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface ProduktlyProviderProps {
  children: React.ReactNode;
}

declare global {
  interface Window {
    Produktly: any;
  }
}

export const ProduktlyProvider = ({ children }: ProduktlyProviderProps) => {
  const router = useRouter();

  useEffect(() => {
    if (!window.Produktly) return;

    window.Produktly.configure({
      redirect: (url: string) => {
        router.push(url);
      },
    });
  }, [window.Produktly]);

  return <>{children}</>;
};

export const useProduktly = () => {
  const [produktly, setProduktly] = useState(null);

  useEffect(() => {
    setProduktly(window.Produktly);
  }, []);

  return produktly;
};
