export const isIpaType = (platformType) => platformType.match('^(ipa|other-online-events)$');

export const ZOOM_JOIN_MEETING_URL_REGEX = /https:\/\/[\w-_.]*zoom.us\/\w+\/(\d{1,11}|([a-zA-Z\d.]){5,40})/i;
export const MEET_JOIN_MEETING_URL_REGEX = /^[https://]*meet.google.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}.*/i;
export const VIMEO_JOIN_MEETING_URL_REGEX = /^https:\/\/vimeo\.com\/(event|webinars\/events)\/[\w-]+(?:\/[\w-]+)?$/i;
export const WEBEX_JOIN_MEETING_URL_REGEX = /^https:\/\/(?:\w+-by\.)?webex\.com\/wbxmjs\/joinservice\/sites\/(?:\w+\/)?meeting\/download\/[a-zA-Z\d]{32}(?:\?.*)?$|https:\/\/\w+\.webex\.com\/wbxmjs\/joinservice\/sites\/\w+\/meeting\/download\/[a-zA-Z\d]{32}(?:\?.*)?$/i;
export const LINKEDIN_JOIN_MEETING_URL_REGEX = /https:\/\/www\.linkedin\.com\/events\/([a-zA-Z0-9]+)\/theater\//i;
export const STREAMYARD_JOIN_MEETING_URL_REGEX = /https:\/\/streamyard\.com\/(?!teams|plan)[a-zA-Z0-9-]+/i;
export const RESTREAM_JOIN_MEETING_URL_REGEX = /https:\/\/studio\.restream\.io\/[a-zA-Z0-9-]+/i;
export const AIRMEET_JOIN_MEETING_URL_REGEX = /^https:\/\/www\.airmeet\.com\/event\/[a-zA-Z0-9-]+$/i;
export const KICK_JOIN_MEETING_URL_REGEX = /^https:\/\/kick\.com\/[a-zA-Z0-9-]+$/i;
