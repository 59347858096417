import { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import { getRandomInt } from '@lib/random';

export const defaultLoadingMessages = [
  'Have you checked out our profanity filter yet?',
  'Did you know StreamAlive was almost called WordsWorth?',
  'Life Pro Tip: You can customize interaction appearances!',
  'Track and convert engagement from our analytics page!',
  'Move your audience from bored-away to blown-away.',
  'Turn your bore-inars into webinars with us!',
  'Turn your presenters into performers!',
  'Congrats! Your presentation is a conversation.',
  'Did you know our GPT integration can come up with your questions for you?',
  'You\'re streaming in the right direction!',
  'The best way to keep your stream running? StreamAlive!',
  'Feeling lost? Check out our help documentation at help.streamalive.com!',
  'Scanning the chat...',
  'Remember to intersperse your slides with interactions!'
];

const Loader = ({
  backgroundColorWrapper = '', positionWrapper = '', zIndexWrapper = 'z-10', className = '', dotsMain = '', messages = '', changeInterval = 10000, showMessages = true, ...property
}) => {
  const arrayOfMessages = messages || defaultLoadingMessages;

  const [index, setIndex] = useState(getRandomInt(0, arrayOfMessages.length));

  let intervalId;

  const nodeRef = useRef(null);

  const getRandomIndex = () => {
    const randIdx = getRandomInt(0, arrayOfMessages.length);
    setIndex((prev) => {
      if (prev === randIdx) {
        return (randIdx + 1) % arrayOfMessages.length;
      }
      return randIdx;
    });
  };

  useEffect(() => {
    intervalId = setInterval(() => {
      getRandomIndex();
    }, parseInt(changeInterval, 10));

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={` loaderWrapper w-full h-full top-0 left-0 ${className || ''} ${backgroundColorWrapper || ''} ${positionWrapper || ''} ${zIndexWrapper || ''}`} {...property}>
      <div className='loaderCover'>
        <div className={`dots ${dotsMain}`}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {showMessages && <CSSTransition
          key={index}
          nodeRef={nodeRef}
          addEndListener={(done) => {
            nodeRef.current.addEventListener('transitionend', done, false);
          }}
          classNames="loaderMessage"
        >
          <div ref={nodeRef} className="h-full relative">
            <div className='text-base text-center text-grey-900 dark:text-grey-200 mt-6 loaderMessage'>
              {(arrayOfMessages[index])}
            </div>
          </div>
        </CSSTransition>}
      </div>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  className: PropTypes.string
};
