import {
  Modal as NextUIModal,
  type ModalProps as NextUIModalProps,
  ModalBody as NextUIModalBody,
  type ModalBodyProps as NextUIModalBodyProps,
  ModalContent as NextUIModalContent,
  type ModalContentProps as NextUIModalContentProps,
  ModalFooter as NextUIModalFooter,
  type ModalFooterProps as NextUIModalFooterProps,
  ModalHeader as NextUIModalHeader,
  type ModalHeaderProps as NextUIModalHeaderProps,
} from '@nextui-org/modal';
import { type SlotsToClasses, forwardRef } from '@nextui-org/react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface ModalProps extends NextUIModalProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  disableOuterScroll?: false | true;
  theme?: string;
  customClassNames?: SlotsToClasses<'backdrop' | 'base' | 'wrapper' | 'header' | 'body' | 'footer' | 'closeButton'> | undefined,
  width?: string;
  height?: string;
}

export const Modal = forwardRef(
  ({
    size = 'md',
    placement = 'top',
    disableOuterScroll,
    theme = '',
    customClassNames,
    width,
    height = '',
    ...props
  }: ModalProps, ref) => {
    const sizes = {
      sm: 'w-110',
      md: 'w-150',
      lg: 'w-200',
      xl: 'w-300',
      full: 'w-full',
    }[size];

    return (
      <NextUIModal
        classNames={customClassNames || {
          wrapper: disableOuterScroll && 'overflow-y-hidden',
          base: `${theme} shadow-[0_25px_50px_-12px_rgba(0,0,0,0.25)] rounded-lg p-7.5 rounded-xl max-w-full dark:bg-grey-900 dark:text-grey-200 dark:border dark:border-grey-800 ${sizes} ${height} ${width}`,
          header: 'p-0 text-xl',
          body: 'p-0 pt-2 gap-0',
          footer: 'p-0 pt-4',
          closeButton:
            'p-3 hover:bg-transparent text-grey-700 hover:text-grey-900 dark:text-grey-200 hover:dark:text-white rounded-none active:bg-transparent',
          backdrop: 'dark:bg-overlay/75'
        }}

        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.2,
                ease: 'easeOut',
              },
            },
            exit: {
              y: 20,
              opacity: 0,
              transition: {
                duration: 0.1,
                ease: 'easeIn',
              },
            },
          },
        }}
        closeButton={
          <div>
            <CloseRoundedIcon fontSize="medium" />
          </div>
        }
        placement={placement}
        ref={ref}
        {...props}
      />
    );
  }
);

interface ModalBodyProps extends NextUIModalBodyProps {}

export const ModalBody = forwardRef((props: ModalBodyProps, ref) => {
  return <NextUIModalBody ref={ref} {...props} />;
});

interface ModalContentProps extends NextUIModalContentProps {}

export const ModalContent = forwardRef((props: ModalContentProps, ref) => {
  return <NextUIModalContent {...props} />;
});

interface ModalFooterProps extends NextUIModalFooterProps {}

export const ModalFooter = forwardRef((props: ModalFooterProps, ref) => {
  return <NextUIModalFooter ref={ref} {...props} />;
});

interface ModalHeaderProps extends NextUIModalHeaderProps {}

export const ModalHeader = forwardRef((props: ModalHeaderProps, ref) => {
  return <NextUIModalHeader ref={ref} {...props} />;
});
