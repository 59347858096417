export const colorPalette = ['#f26a60',
  '#ff9d8d',
  '#ff5497',
  '#55dbab',
  '#b1d878',
  '#a9cafb',
  '#ffe956',
  '#b99ac7',
  '#81b1fa'];
//
// '#E57373',
//   '#6495ED',
//   '#9ACD32',
//   '#FF66B2',
//   '#20B2AA',
//   '#D084B3',
//   '#FFFFE0',
//   '#FF8C00',
//   '#9370DB',
//   '#40E0D0',
//   '#B4D330',
//   '#87CEFA',
//   '#FF7F50',
//   '#F5DEB3',
//   '#C71585',
