import {
  Dropdown as NextUIDropdown,
  DropdownItem as NextUIDropdownItem,
  DropdownMenu as NextUIDropdownMenu,
  DropdownTrigger as NextUIDropdownTrigger,
  DropdownSection as NextUIDropdownSection,
} from '@nextui-org/dropdown';
import { extendVariants } from '@nextui-org/react';
import styled from 'styled-components';

export const Dropdown = styled(NextUIDropdown).attrs({
  className: 'rounded-md',
  classNames: {
    content: 'px-0 py-1.25 rounded-md dark:bg-grey-900 dark:border dark:border-grey-800 shadow-lg',
  },
})``;

export const DropdownTrigger = extendVariants(NextUIDropdownTrigger, {});

export const DropdownMenu = styled(NextUIDropdownMenu).attrs({
  className: '!p-0',
  classNames: {
    list: 'gap-1',
  },
  itemClasses: {
    base: [
      'rounded-none',
      'py-1.5',
      'px-4',
      'text-grey-900',
      'dark:text-grey-200',
      'data-[hover=true]:text-grey-900',
      'data-[hover=true]:dark:text-grey-200',
      'data-[hover=true]:bg-grey-100',
      'data-[hover=true]:dark:bg-grey-800',
      'data-[selectable=true]:focus:bg-dummy',
    ],
  },
})``;

export const DropdownItem = extendVariants(NextUIDropdownItem, {});

export const DropdownSection = styled(NextUIDropdownSection).attrs(({
  classNames: {
    heading: 'uppercase font-medium text-grey-500 px-4 py-2 text-sm',
  },
}))``;
