import { useContext } from 'react';
import Button from '@atoms/new/button';
import { NoticeBanner } from '@atoms/new/NoticeBubble';
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import { useRouter } from 'next/router';

import { StreamDataContext } from '@components/context/StreamContext';

type OverlayPlacement = 'top' | 'bottom' | 'right' | 'left' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end' | 'right-start' | 'right-end';

interface PricingAlertNudgeProps {
  title: string;
  description: string;
  onClose: () => void;
}

export const PricingAlertNudge = ({ title, description, onClose }: PricingAlertNudgeProps) => {
  const { isPresentationLive } = useContext(StreamDataContext);
  const router = useRouter();

  const handleClick = () => {
    if (isPresentationLive) {
      window.open('/pricing', '_blank');
    } else {
      router.push('/pricing');
    }
  };

  return (
    <div>
      <NoticeBanner variant='warning'>
        <div className='flex flex-col gap-2'>
          <h3 className='text-sm font-semibold dark:text-grey-900'>{title}</h3>
          <p className='text-sm font-normal mb-2 dark:text-grey-800'>{description}</p>
          <Button className='w-fit' kind='secondary' onClick={handleClick}>Upgrade</Button>
        </div>
      </NoticeBanner>
    </div>
  );
};

interface PricingAlertNudgePopoverProps extends PricingAlertNudgeProps {
  trigger: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  placement?: OverlayPlacement;
  offset?: number;
  disabled?: boolean;
  crossOffset?: number;
}

export const PricingAlertNudgePopover = ({
  title,
  description,
  trigger,
  isOpen,
  onClose,
  placement = 'bottom-end',
  offset = 7,
  crossOffset = 0
}: PricingAlertNudgePopoverProps) => {
  return (
    <Popover
      classNames={{ content: 'p-0', trigger: 'aria-expanded:opacity-100 aria-expanded:scale-100' }}
      color='warning'
      placement={placement}
      isOpen={isOpen}
      crossOffset={crossOffset}
      onClose={onClose}
      offset={offset}
    >
      <PopoverTrigger>
        {trigger}
      </PopoverTrigger>

      <PopoverContent>
        <PricingAlertNudge
          title={title}
          description={description}
          onClose={() => {}}
        />
      </PopoverContent>
    </Popover>
  );
};
