import { useEffect, useState, useMemo } from 'react';
import { useQuery, useQueries } from '@tanstack/react-query';
import { invokeZoomAppsSdk } from '@zoom-app/apis';
import { isMeetingContext, isHostOrCoHost, getResourceType } from '@zoom-app/helpers';
import { getZoomResource } from '@services/zoom';

interface useMeetingProps {
  headerContext: any;
  platform: any;
  sdkInitialized: boolean;
}

const STALE_TIME = 60000; // 1 minute

const useMeeting = ({ headerContext, platform, sdkInitialized }: useMeetingProps) => {
  const [meetingInfo, setMeetingInfo] = useState({
    isMeeting: false,
    isHost: false,
    resourceType: '',
    fetchResource: false,
  });

  useEffect(() => {
    const isMeeting = headerContext?.typ && isMeetingContext(headerContext.typ);
    const isHost = headerContext?.attendrole && isHostOrCoHost(headerContext.attendrole);
    const resourceType = getResourceType(headerContext?.typ) || 'meetings';
    setMeetingInfo({ isMeeting, isHost, resourceType, fetchResource: false });
  }, [headerContext]);

  const queries = useMemo(() => {
    if (!meetingInfo.isMeeting || !sdkInitialized) return [];
    const baseQueries = [
      {
        queryKey: ['meetingUUID'],
        queryFn: () => invokeZoomAppsSdk({ name: 'getMeetingUUID' }),
        staleTime: STALE_TIME,
      },
    ];
    if (meetingInfo.isHost) {
      baseQueries.push(
        {
          queryKey: ['meetingContext'],
          queryFn: () => invokeZoomAppsSdk({ name: 'getMeetingContext' }),
          staleTime: STALE_TIME,
        },
        {
          queryKey: ['joinMeetingUrl'],
          queryFn: () => invokeZoomAppsSdk({ name: 'getMeetingJoinUrl' }),
          staleTime: STALE_TIME,
        },
        {
          queryKey: ['runningContext'],
          queryFn: () => invokeZoomAppsSdk({ name: 'getRunningContext' }),
          staleTime: STALE_TIME,
        }
      );
    }
    return baseQueries;
  }, [meetingInfo.isMeeting, meetingInfo.isHost, sdkInitialized]);

  const combinedQueries = useQueries({
    queries,
    combine: (results: any) => {
      const meetingData = results.reduce((acc: any, result: any) => {
        if (result.data) {
          return { ...acc, ...result.data };
        }
        return acc;
      }, {});
      return {
        data: meetingData,
        isPending: results.some((result: any) => result.isLoading),
      };
    },
  });

  useEffect(() => {
    if (
      combinedQueries.data?.meetingUUID &&
      platform?.is_authorized &&
      meetingInfo.resourceType
    ) {
      setMeetingInfo((prev) => ({ ...prev, fetchResource: true }));
    }
  }, [combinedQueries.data, platform, meetingInfo.resourceType]);

  const { data: apiResource } = useQuery({
    queryKey: ['apiResource', platform?.id],
    queryFn: () =>
      getZoomResource(
        meetingInfo.resourceType || 'meetings',
        combinedQueries.data.meetingID,
        { platformId: platform?.id }
      ),
    enabled: !!meetingInfo.fetchResource && !!combinedQueries?.data?.meetingID,
    staleTime: STALE_TIME,
  });

  const meeting = { ...combinedQueries.data };
  if (apiResource?.entity) {
    meeting.apiResource = apiResource.entity;
  }

  return {
    isMeeting: meetingInfo.isMeeting,
    isHost: meetingInfo.isHost,
    meeting,
    isPending: combinedQueries.isPending
  };
};

export default useMeeting;
