/* eslint-disable max-len */
import React, { useEffect, createContext, useState } from 'react';
import { useRouter } from 'next/router';

import Modal from '@molecules/Modal';
import TeamSwitchWarning from '@components/SATeams/TeamSwitchWarning';

import Loader from '@atoms/Atom/Loader';
import { googleLogout } from '@react-oauth/google';

import {
  getAuth,
  removeAuth,
  getCurrentTeamToken,
  setCurrentTeamToken,
  removeCurrentTeamToken
} from '@services/identity.service';

import {
  generateTeamToken,
  verifyTeamToken,
  getUserAccountById,
  getUserTeams,
  restrictCopilot
} from '@services/youtube-platform.service';
import { usePostHog } from 'posthog-js/react';

export const TeamsDataContext = createContext();

const detailRoutes = [
  '/my-teams/[teamId]',
  '/zoom/[platformId]',
  '/youtube/[platformId]',
  '/teams/[platformId]',
  '/ipa/[platformId]',
  '/stats/[streamId]',
  '/streams/[streamId]/prepare'
];

export const TeamsDataProvider = ({ children }) => {
  const [teams, setTeams] = useState([]);
  const [user, setUser] = useState();
  const [ownTeam, setOwnTeam] = useState([]);
  const [currentTeam, setCurrentTeam] = useState();
  const [teamContext, setTeamContext] = useState({
    oid: null
  });
  const [teamMessages, setTeamMessages] = useState([]);
  const [teamErrors, setTeamErrors] = useState([]);
  const [modal, setModal] = useState(false);
  const [newTeam, setNewTeam] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const [celloUcc, setCelloUcc] = useState(null);

  const auth = getAuth();
  const posthog = usePostHog();
  const router = useRouter();

  const filterTeamsByRole = (role, negate = false) => teams.filter(
    (t) => (negate ? t.role !== role : t.role === role)
  );

  const toggleModal = () => setModal(!modal);

  const isSelected = (id) => id === currentTeam?.billing_account?.id;

  const isTeamAdmin = () => currentTeam?.role?.match('^(owner|editor)$');

  const isTeamOwner = () => currentTeam?.role?.match('^(owner)$');

  const isTeamModerator = () => currentTeam?.role?.match('^(owner|editor|moderator)$');

  const getCurrentTeam = async () => {
    const own = teams.find((t) => t.role === 'owner');
    setOwnTeam(own);

    const teamToken = getCurrentTeamToken();
    let team;

    if (teamToken) {
      const teamData = await verifyTeamToken(teamToken);
      if (teamData) {
        team = teams.find((t) => t.billing_account.id === teamData.entity.tid);
      }
    }

    if (team) {
      return team;
    }

    team = own;
    return team;
  };

  const doLogout = () => {
    posthog?.capture('Logged Out');
    googleLogout();
    removeAuth();
    removeCurrentTeamToken();
    setCurrentTeam();
    setTeamContext();
  };

  const checkIsCopilot = async () => {
    const res = await restrictCopilot(router.pathname);
    if (res.status) return;
    doLogout();
  };

  const updateTeam = (updatedTeam) => {
    const tmpTeams = [...teams];
    tmpTeams.forEach((t) => {
      if (t.billing_account.id === updatedTeam.id) {
        // eslint-disable-next-line no-param-reassign
        t.billing_account = { ...t.billing_account, ...updatedTeam };
      }
    });
    setTeams(tmpTeams);
  };

  const switchTeam = (team) => {
    if (team.billing_account.owner_id === teamContext?.oid) return;

    generateTeamToken(team.billing_account.id).then((data) => {
      if (!data.status) return;
      setCurrentTeamToken(data.entity.token);
      setTeamContext({
        name: team.billing_account.name,
        tid: team.billing_account.id,
        oid: team.billing_account.owner_id,
        utid: team.id,
        role: team.role
      });
      setCurrentTeam(team);
    });
  };

  const confirmSwitch = () => {
    switchTeam({ ...newTeam });
    setNewTeam();
    toggleModal();
    router.push('/');
  };

  const selectTeam = (team) => {
    const route = detailRoutes.find((pathname) => pathname === router.pathname);
    if (route) {
      setNewTeam(team);
      toggleModal();
    } else {
      switchTeam(team);
    }
  };

  const getBrandLogo = (defaultImageUrl) => {
    if (currentTeam?.billing_account?.purchase_plan?.custom_branding_allowed) {
      return currentTeam?.billing_account?.brand_logo || defaultImageUrl;
    }

    return defaultImageUrl;
  };

  const isLogoRequired = () => {
    if (currentTeam?.billing_account?.purchase_plan?.no_branding_allowed) {
      return currentTeam.billing_account.is_branding_required !== false;
    }
    return true;
  };

  const initTeam = async () => {
    if (!getAuth()) {
      setIsLoading(false);
      return;
    }

    const [userResponse, teamsResponse] = await Promise.all([
      getUserAccountById(),
      getUserTeams()
    ]);

    if (userResponse.status && userResponse.entity) {
      setUser(userResponse.entity);
    }
    if (teamsResponse.status && teamsResponse.entity) {
      setTeams(teamsResponse.entity);
    }
    setIsLoading(false);
  };

  const isLoggedIn = () => !!user && user?.user_role !== 'guest';

  useEffect(() => {
    const getTeam = async () => {
      const currTeam = await getCurrentTeam();
      switchTeam(currTeam);
    };
    if (teams.length > 0) {
      getTeam();
    }
    checkIsCopilot();
  }, [teams]);

  useEffect(() => {
    setIsClient(true);
    initTeam();
  }, []);

  if (!isClient) {
    return null;
  }

  if (isLoading || (auth && !teamContext?.oid)) {
    if (['sandbox'].includes(process.env.NEXT_PUBLIC_APP_ENV)) {
      return null;
    }

    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <TeamsDataContext.Provider
      value={{
        initTeam,
        user,
        teams,
        setTeams,
        currentTeam,
        teamContext,
        setCurrentTeam,
        filterTeamsByRole,
        updateTeam,
        teamErrors,
        setTeamErrors,
        teamMessages,
        setTeamMessages,
        selectTeam,
        ownTeam,
        isSelected,
        isTeamOwner,
        isTeamAdmin,
        isTeamModerator,
        doLogout,
        getBrandLogo,
        isLogoRequired,
        isLoggedIn,
        celloUcc,
        setCelloUcc
      }}
    >
      {children}
      {modal && (
        <Modal>
          <TeamSwitchWarning onCancel={toggleModal} onSuccess={confirmSwitch} />
        </Modal>
      )}
    </TeamsDataContext.Provider>
  );
};
