import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { LightMode, DarkMode } from '@mui/icons-material';
import Button from '@atoms/new/button';

interface ThemeToggleProps {
  isExpanded?: boolean;
}

export default function ThemeToggle({ isExpanded }: ThemeToggleProps) {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <div className={`absolute ${!isExpanded ? 'right-0.5 top-0.5' : 'right-6 top-5'}`}>
      <Button
        size='sm'
        kind='icon-only'
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        className='rounded-full'
      >
        {theme === 'dark' ? (
          <LightMode fontSize='small' className='text-grey-200' />
        ) : (
          <DarkMode fontSize='small' className='text-gray-900' />
        )}
      </Button>
    </div>
  );
}
