// Mozilla/5.0 ZoomWebKit/537.36 (KHTML, like Gecko) ZoomApps/1.0
import { ZOOMAPP_CONTEXT_HEADER, getAppContext } from '@zoom-app/cipher';
import { ZOOM_JOIN_MEETING_URL_REGEX } from '@services/platform-service';
import * as yup from 'yup';

export const ZOOMAPP_CONNECT_COOKIE = 'sa-connect';
export const ZOOMAPP_SESSION_COOKIE = 'sa-session';

export const _isZoomApp = (userAgentString) => userAgentString.match(/zoom/i);

export const isZoomApp = () => {
  if (typeof window !== 'undefined') {
    return _isZoomApp(window.navigator.userAgent);
  }
  return false;
};

export const zoomAppBasePath = process.env.NEXT_PUBLIC_ZM_BASE_PATH;

export const redirectToZoomApp = (router) => {
  if (isZoomApp()) {
    router.push(zoomAppBasePath);
  }
};

export const isMeetingContext = (context = '') => {
  if (context?.match(/^(inMeeting|inWebinar|meeting|webinar)$/i)) {
    return true;
  };
  return false;
};

export const getResourceType = (context = '') => {
  if (context.match(/inMeeting|meeting/i)) {
    return 'meetings';
  }
  if (context.match(/inWebinar|webinar/i)) {
    return 'webinars';
  }
};

export const getErrorMessage = (error) => {
  let errorMessage;
  if (error?.reason) {
    errorMessage = error.reason;
  } else {
    errorMessage = 'Something went wrong. Please try again.';
  }
  return errorMessage;
};

export const signupLink = `${process.env.NEXT_PUBLIC_URL}signup?utm_source=zoomapp&utm_medium=app`;

export const sandboxLink = process.env.NEXT_PUBLIC_SANDBOX_URL;

export const isHostOrCoHost = (role = '') => role.match(/^(host|co-host|coHost)$/i);

export const meetingLinkSchema = yup.object().shape({
  meeting_link: yup.string().test({
    name: 'meeting_link',
    test(value, ctx) {
      if (!value) {
        return ctx.createError({ message: 'Please enter your meeting link' });
      }

      if (value.includes('register')) {
        return ctx.createError({ message: 'Registration link cannot be used to join a meeting/webinar.' });
      }

      if (!ZOOM_JOIN_MEETING_URL_REGEX.test(value)) {
        return ctx.createError({ message: 'Enter a valid join meeting link' });
      }

      // Check for multiple pastes (duplicates in URL)
      const segments = value.split('/');
      const uniqueSegments = new Set(segments);

      if (segments.length !== uniqueSegments.size) {
        return ctx.createError({ message: 'Duplicate link detected. Please paste only once.' });
      }

      return true;
    }
  }),
});

export const isWindows = (userAgent) => userAgent?.match(/windows/i);
